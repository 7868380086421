import React from "react";
import ImageTextModule from "@components/modules/image-text/";
import { useStaticQuery, graphql } from "gatsby";

const StaticImage = (props)=> {
    const imgUrl = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "our-client-get-started-tile.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)

    return (
      <ImageTextModule
                    imgUrl={imgUrl.file.childImageSharp.fluid}
                />    
    )

}

export default StaticImage

